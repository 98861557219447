// Generated by Framer (c75d380)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["W4vX2KssA"];

const serializationHash = "framer-fuyJ8"

const variantClassNames = {W4vX2KssA: "framer-v-b6m7xt"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({country, countryFlag, height, id, radius, width, ...props}) => { return {...props, Ekv7RJ2p_: countryFlag ?? props.Ekv7RJ2p_ ?? {src: "https://framerusercontent.com/images/Orj3RkmKscZ1KIihxnEID3HbzE.svg"}, pBJblCARJ: country ?? props.pBJblCARJ ?? "Austria", u5aYZ72eW: radius ?? props.u5aYZ72eW ?? 100} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;country?: string;countryFlag?: {src: string; srcSet?: string};radius?: number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, pBJblCARJ, Ekv7RJ2p_, u5aYZ72eW, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "W4vX2KssA", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-b6m7xt", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"W4vX2KssA"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fill", sizes: "48px", ...toResponsiveImage(Ekv7RJ2p_)}} className={"framer-1iz4ek6"} data-framer-name={"AT"} layoutDependency={layoutDependency} layoutId={"E8jiESOrM"} style={{borderBottomLeftRadius: u5aYZ72eW, borderBottomRightRadius: u5aYZ72eW, borderTopLeftRadius: u5aYZ72eW, borderTopRightRadius: u5aYZ72eW}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "18px", "--framer-line-height": "28px"}}><motion.span style={{"--font-selector": "SW50ZXI=", "--framer-font-family": "\"Inter\"", "--framer-font-size": "18px", "--framer-letter-spacing": "-0.25px", "--framer-text-color": "var(--extracted-1w3ko1f, rgba(0, 44, 21, 1))"}}>Austria</motion.span></motion.p></React.Fragment>} className={"framer-dwdi2c"} data-framer-name={"Austria"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"mmNpXpbD0"} style={{"--extracted-1w3ko1f": "rgba(0, 44, 21, 1)", "--framer-paragraph-spacing": "18px"}} text={pBJblCARJ} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-fuyJ8[data-border=\"true\"]::after, .framer-fuyJ8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fuyJ8.framer-1g5nlqm, .framer-fuyJ8 .framer-1g5nlqm { display: block; }", ".framer-fuyJ8.framer-b6m7xt { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 20px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 220px; }", ".framer-fuyJ8 .framer-1iz4ek6 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 48px); overflow: hidden; position: relative; width: 48px; will-change: var(--framer-will-change-override, transform); }", ".framer-fuyJ8 .framer-dwdi2c { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-fuyJ8.framer-b6m7xt { gap: 0px; } .framer-fuyJ8.framer-b6m7xt > * { margin: 0px; margin-bottom: calc(20px / 2); margin-top: calc(20px / 2); } .framer-fuyJ8.framer-b6m7xt > :first-child { margin-top: 0px; } .framer-fuyJ8.framer-b6m7xt > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 96
 * @framerIntrinsicWidth 220
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"pBJblCARJ":"country","Ekv7RJ2p_":"countryFlag","u5aYZ72eW":"radius"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framert2GDOyS5g: React.ComponentType<Props> = withCSS(Component, css, "framer-fuyJ8") as typeof Component;
export default Framert2GDOyS5g;

Framert2GDOyS5g.displayName = "Elements / Country-name";

Framert2GDOyS5g.defaultProps = {height: 96, width: 220};

addPropertyControls(Framert2GDOyS5g, {pBJblCARJ: {defaultValue: "Austria", displayTextArea: false, title: "Country", type: ControlType.String}, Ekv7RJ2p_: {__defaultAssetReference: "data:framer/asset-reference,Orj3RkmKscZ1KIihxnEID3HbzE.svg?originalFilename=AT.svg&preferredSize=auto", title: "Country flag", type: ControlType.ResponsiveImage}, u5aYZ72eW: {defaultValue: 100, title: "Radius", type: ControlType.Number}} as any)

addFonts(Framert2GDOyS5g, [])